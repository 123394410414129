import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export const CarouselRoi = () => {
  const data = useStaticQuery(graphql`
    query {
      allRoiJson {
        nodes {
          title
          src {
            childImageSharp {
              fixed(width: 294) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
          description
        }
      }
    }
  `);

  return (
    <ol className="carousel__viewport">
      {data.allRoiJson.nodes.map((roi, index) => (
        <li key={index} className="carousel__slide">
          <h4 dangerouslySetInnerHTML={{ __html: `${roi.title}` }} />
          <Img fixed={roi.src.childImageSharp.fixed} alt={roi.alt} />
          <p dangerouslySetInnerHTML={{ __html: `${roi.description}` }} />
        </li>
      ))}
    </ol>
  );
};
