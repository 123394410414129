import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export const CarouselProperties = () => {
  const data = useStaticQuery(graphql`
    query {
      allPropertiesJson {
        nodes {
          src {
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
      }
    }
  `);

  return (
    <ol className="carousel__viewport">
      {data.allPropertiesJson.nodes.map((roi, index) => (
        <li key={index} className="carousel__slide">
          <Img fixed={roi.src.childImageSharp.fixed} alt={roi.alt} />
        </li>
      ))}
    </ol>
  );
};
